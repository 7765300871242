<template>
  <title-wrapper :title="$t('helpers.register')">
    <register-form></register-form>
  </title-wrapper>
</template>
<script>
import RegisterForm from '../components/RegisterForm.vue';
import TitleWrapper from '../components/TitleWrapper.vue';
export default {
  name: 'Register',
  components: { RegisterForm, TitleWrapper },
};
</script>
<style lang="scss" scoped></style>
