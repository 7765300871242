import config from '../../configuration.json';
import axios from 'axios';
import QueryHandler from '../utils/QueryHandler';

const url = `${config.VUE_APP_API_URL}/companies`;

axios.defaults.withCredentials = true;

class CompanyService {
  static async getAllCompanyUsers(id, params = {}) {
    const resultUrl = QueryHandler.buildQuery(`${url}/users/${id}`, params);

    const res = await axios.get(resultUrl);
    return res.data;
  }

  static async getAllTraderCompanies(id, params = {}) {
    const resultUrl = QueryHandler.buildQuery(`${url}/salesmen/${id}`, params);
    const res = await axios.get(resultUrl);
    return res.data;
  }

  static async get(id) {
    const res = await axios.get(`${url}/${id}`);
    return res.data;
  }
  static async update(id, company) {
    const res = await axios.put(`${url}/${id}`, { ...company });
    return res.data;
  }

  static async add(company) {
    const res = await axios.post(`${url}`, { ...company });
    return res.data;
  }

  static async updateLogo(shopId, file) {
    var formData = new FormData();
    formData.append('companyLogo', file);
    const res = await axios.put(`${url}/logo/${shopId}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    return res.data;
  }

  static async deleteLogo(shopId) {
    const res = await axios.delete(`${url}/logo/${shopId}`);
    return res.data;
  }

  static async getDiscountGroups() {
    const res = await axios.get(`${url}/enum/discountGroups`);
    return res.data;
  }

  static async resetNipLink(companyId) {
    const res = await axios.get(`${url}/reset-nip/${companyId}`);
    return res.data;
  }

  static async getCompanyWithNipToken(token) {
    const res = await axios.get(`${url}/company-nip-token`, {
      headers: {
        'nip-token': token,
      },
    });

    return res.data;
  }

  static async resetNip(companyId, nip, token) {
    const res = await axios.post(
      `${url}/company-nip-token/${companyId}`,
      { nip },
      {
        headers: {
          'nip-token': token,
        },
      }
    );

    return res.data;
  }

  static async syncCompanyData(companyId) {
    const res = await axios.get(`${url}/sync-company-data/${companyId}`);

    return res.data;
  }
}

export default CompanyService;
