import config from '../../configuration.json';
import axios from 'axios';

const url = `${config.VUE_APP_API_URL}/nip`;

axios.defaults.withCredentials = true;

class NipService {
  static async getDataByNip(nip) {
    const res = await axios.get(`${url}/${nip}`);
    const dataObj = res.data.data;
    for (const prop in dataObj) {
      dataObj[prop] = dataObj[prop].shift();
    }
    return dataObj;
  }
}

export default NipService;
